<template>
    <div class="schedule">
        <!-- 日程安排 -->
        <div class="center">
            <aside-part></aside-part>
            <div class="content">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>日程安排</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="title">
                    <div class="title_zh">日程安排</div>
                    <div class="title_en">Schedule</div>
                </div>
                <!-- <div class="box">
                    <img src="@/assets/ing.png" alt="">
                    <p>内容更新中...</p>
                </div> -->
                <div class="block">
                    <el-timeline>
                        <el-timeline-item v-for="(item,index) in scheduleList" :key="index" :timestamp=item.date placement="top">
                            <el-card>
                                <h4>{{ item.title }}</h4>
                                <div class="scheduleItem" v-for="(i,indexs) in item.details" :key="indexs">
                                    <div class="time">{{ i.time }}</div>
                                    <div>{{ i.content }}</div>
                                </div>
                            </el-card>
                        </el-timeline-item>
                    </el-timeline>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import asidePart from '@/components/asidePart.vue';
export default {
    name: 'schedule',
    components: {
        asidePart
    },
    data() {
        return {
            scheduleList: []
        }
    },
    mounted() {
       this.getDate();
    },
    methods: {
        processingDataSchedule() {
            this.scheduleList = this.Alldata.conferenceSchedule
        }
    }
}
</script>

<style lang="less" scoped>
.center {
    display: flex;
    align-items: top;
    flex-shrink: 0;

    .content {
        padding: 30px;
        width: calc(100% - 60px);

        .title {
            margin-top: 20px;
        }

        .box {
            width: 100%;
            height: 500px;
            text-align: center;
            font-size: 16px;
            color: #0b88e0;
            opacity: 0.5;

            img {
                width: 300px;
            }
        }

        .block {
            .scheduleItem {
                display: flex;
                align-items: center;
                font-size: 16px;
                color: #666666;
                margin-top: 10px;

                .time {
                    color: #2B77AE;
                    // font-weight: bold;
                    min-width: 100px;
                    text-align: right;
                    margin-right: 20px;
                }
            }
        }
    }
}
</style>